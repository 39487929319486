import dynamic from 'next/dynamic';

const Layout = dynamic(() => import('./Layout'));
const Meta = dynamic(() => import('./Meta'));
const Header = dynamic(() => import('./Header'));
const Hero = dynamic(() => import('./Hero'));
const BlockWrapper = dynamic(() => import('./BlockWrapper'));
const Link = dynamic(() => import('./Link'));
const Chatbot = dynamic(() => import('./Chatbot'));
const Footer = dynamic(() => import('./Footer'));

export { BlockWrapper, Header, Hero, Layout, Link, Meta, Chatbot, Footer };
